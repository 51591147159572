import 'babel-polyfill'
import 'react-app-polyfill/ie11'
import React, { Component } from 'react'
import Layout from '../../components/layout';
// import { getObject } from '../../services/storage'
// import {S3ENV} from '../../components/envar'
import { Container, Table, Row } from 'reactstrap'
import Breadcrumb from '../../components/breadcrumb'
import ReactHtmlParser from 'react-html-parser'
import { Banner } from '../../components/banner';
import style from '../../styles/schedule.css';
import { handleCmsFile } from '../../services/api'

export default class schedule extends Component {
    constructor(props) {
        super(props);

        this.state = {
            objItems: false,
        }; 
    }

    async componentDidMount() {

        let result
        var data = {
            stage_file: 'staging-maintenance.json',
            status: "current"
        }

        
        let files = await handleCmsFile(data)
        // console.log(files)
        files.data.forEach(function(item,key){
            if(item.status === 'current'){
                result = item.data      
            }
        })

        console.log(result)
        this.setState({
            objItems: result
        })
    }

    render() {
        var content = this.state.objItems
        return (
            <Layout>
                <Banner title={`Maintenance Schedule`} />
                <Container
                style={{
                    padding: '2rem 2rem 1rem',
                    minHeight: '75vh'
                }}
                >
                <Row>
                    <div
                        className="container-fluid"
                        dangerouslySetInnerHTML={{ __html: content }}
                    />
                </Row>
            </Container>
            </Layout>


        )
    }
}


